.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 150px;
  }
  .disclaimer {
    margin: 15px 0;
    border: 1px dashed #97d8ff;
    padding: 10px;
    background-color: #d7f0ff;
    color: #49bbff;
    font-weight: bold;
    font-size: 12px;
  }
  .warning {
    margin: 15px 0;
    border: 1px dashed #eec300;
    padding: 10px;
    background-color: #fae9c2;
    color: #c2a009;
    font-weight: bold;
    font-size: 12px;
  }
.container-response {
    width: 768px;
    text-align: justify;
    padding: 20px;
    box-shadow: 0px 0px 5px 0px #ccc;
    margin-bottom: 20px;
}

.response {
  white-space: pre-wrap;
}
  
.form {
    width: 700px;
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f0f0f0;
    text-align: center;
  }
  
  header h1 {
    color: #0051a9;
    margin-bottom: 0;
  }
  header h4 { margin-top: 0; }
  textarea {
    width: 750px;
    height: 300px;
    margin-bottom: 20px;
    border-radius: 20px;
    border: 2px solid #0051a9;
    padding: 18px;
  }
  .auth-form {
    width: 90%;
    margin: 120px auto;
    text-align: center;
    padding: 20px;
    background-color: #f2f2f2;
  }
  
  .toggle-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .buttons-inline {
    text-align: center;
    padding: 20px;
  }
  
  .button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
    background-color: #0051a9;
    transition: 0.3s ease-in-out;
  }
  
  .button:hover {
    background-color: #0069d9;
  }
  
  .active {
    background-color: #0069d9;
  }
  
  .connection-form {
    display: block;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
  }
  
  .registration-form {
    display: block;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
  }
  
  h2 {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
  }
  
  input {
    padding: 10px;
    outline: none;
    border: 1px solid #f2f2f2;
    border-radius: 20px;
    outline: none;
    margin: 0 5px;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
    background-color: #0051a9;
    transition: 0.3s ease-in-out;
    margin: 20px 5px 0 5px;
    border-radius: 20px;
  }
  
  button:hover {
    background-color: #0069d9;
  }

  @media only screen and (max-width: 768px) {
    .disclaimer { width: 80%; }
    header {
      display: block!important;
      text-align: center;
    }
    header > div {
      margin-left: 0!important;
    }
    .container {
      margin-top: 130px;
    }
    .container-response, .form, .auth-form {
      width: 100%;
    }
    h2 {
      font-size: 18px;
    }
    .response {
      width: 90%;
      margin: auto;
    }
    .container-response h2 { padding-left: 5px;}
    input, button, textarea {
      width: 80%;
    }
    .toggle-buttons { display: block; }
    .auth-form { padding: 20px 0; }
    .iconButton { width: 91%; }
  }